import "./index.css";
import "react-notifications-component/dist/theme.css";

import App from "./App";
import React from "react";
import ReactDOM from "react-dom/client";
import { ReactNotifications } from "react-notifications-component";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ReactNotifications style={{ zIndex: 9999 }} />
    <App />
  </React.StrictMode>
);
