import { convertJsonToFunction, isValidJson } from "functions";
import { useEffect, useState } from "react";

import arrowRight from "arrow-right.svg";
import checkmark from "check.svg";
import logo from "logo-white.png";
import mixpanel from "mixpanel-browser";
import styled from "styled-components";
import { successNotification } from "Notification";
import { theme } from "theme";
import x from "x.svg";

const App = () => {
  const [input, setInput] = useState("");
  const [results, setResults] = useState("");
  const [isMixpanelReady, setIsMixpanelReady] = useState(false);

  useEffect(() => {
    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Function to initialize Mixpanel
    const initializeMixpanel = () => {
      // Assuming mixpanel is globally available (e.g., loaded via a script tag)
      if (mixpanel) {
        // You can add additional initialization code for mixpanel here if needed
        setIsMixpanelReady(true);
      }
    };

    // Call the initialization function
    initializeMixpanel();

    // Optional: Retry initialization if mixpanel is not available immediately
    const intervalId = setInterval(() => {
      if (!isMixpanelReady) {
        initializeMixpanel();
      } else {
        clearInterval(intervalId);
      }
    }, 1000); // Check every 1 second

    // Cleanup function
    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isMixpanelReady) {
      mixpanel.track("Page View");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMixpanelReady]);

  const convertJson = () => {
    mixpanel.track("Convert JSON");
    const result = convertJsonToFunction(input);
    console.log(result);

    if (result) {
      setResults(JSON.stringify(result, null, 2));
    } else {
      setResults("Your JSON is invalid.");
    }
  };

  const isValid = input === "" ? false : isValidJson(input);

  return (
    <Container>
      <img
        src={logo}
        style={{ height: "50px", marginBottom: "20px" }}
        alt="Logo"
      />
      <Title>JSON To ChatGPT - Function Object Converter</Title>
      <Subtitle>
        ChatGPT function calling requires the creation of a specific JSON
        structure. This tool makes it easier.
      </Subtitle>
      <Row>
        <Box>
          <BoxTitle>JSON</BoxTitle>
          <StyledTextArea
            placeholder="Paste the JSON object here that you want ChatGPT to generate"
            onChange={(e) => setInput(e.target.value)}
          />
          <ValidationRow display={input.length > 0 ? "true" : undefined}>
            <ValidationText>
              {isValid ? "Your JSON is valid" : "Your JSON is invalid"}
            </ValidationText>
            <CheckWrapper valid={isValid ? "true" : undefined}>
              <img
                src={isValid ? checkmark : x}
                alt="checkmark"
                style={{ height: "14px" }}
              />
            </CheckWrapper>
          </ValidationRow>
        </Box>
        <ConvertButton onClick={convertJson}>
          <span>Convert</span>
          <img src={arrowRight} alt="arrow right" />
        </ConvertButton>
        <Box>
          <BoxTitle>Function Object</BoxTitle>
          <StyledTextArea
            placeholder="Your function calling object will appear here after conversion"
            value={results}
            onChange={() => null}
            onClick={() => {
              if (isValid && results) {
                console.log("COPIED!");
                navigator.clipboard.writeText(results);
                successNotification("Copied to clipboard!");
              }
            }}
          />
          <ValidationRow
            display={
              results.length > 0 && results !== "Your JSON is invalid."
                ? "true"
                : undefined
            }
          >
            <ValidationText>Click to copy results to clipboard</ValidationText>
          </ValidationRow>
        </Box>
      </Row>
    </Container>
  );
};

export default App;

const ConvertButton = styled.button`
  font-size: 20px;
  font-weight: 500;
  padding: 18px;
  background: #1c45c9;
  border: 0px;
  border-radius: 22px;
  color: white;
  cursor: pointer;
  transition: 0.2s;
  outline: none;
  margin-top: 60px;
  display: flex;
  align-items: center;
  gap: 5px;

  @media (max-width: 1200px) {
    margin-top: 0px;
  }
`;

const Title = styled.div`
  font-size: 40px;
  font-weight: 700;
  color: white;
  text-align: center;
`;

const Subtitle = styled.div`
  font-size: 20px;
  font-weight: 300;
  color: ${theme.lightText};
  margin-top: 10px;
  text-align: center;
  line-height: 25px;
`;

const Container = styled.div`
  padding: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const ValidationRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  transition: opacity 0.5s ease-out;
  -moz-transition: opacity 0.5s ease-out;
  -webkit-transition: opacity 0.5s ease-out;
  -o-transition: opacity 0.5s ease-out;
  opacity: ${(p) => (p.display ? 1 : 0)};
  margin-top: 10px;
  height: 30px;
`;

const ValidationText = styled.div`
  font-size: 15px;
  font-weight: 300;
  color: white;
`;

const CheckWrapper = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: ${(p) => (p.valid ? "#74c98f99" : "#df516e99")};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  margin-top: 70px;
  @media (max-width: 900px) {
    margin-top: 50px;
    flex-direction: column;
  }
`;

const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const BoxTitle = styled.div`
  font-size: 28px;
  font-weight: 700;
  color: white;
  margin-bottom: 15px;
`;

const StyledTextArea = styled.textarea`
  font-size: 18px;
  font-weight: 300;
  padding: 25px;
  background: ${theme.lightBackground};
  border: 1px solid ${theme.lightBorder};
  width: 400px;
  height: 400px;
  border-radius: 30px;
  resize: none;
  color: white;

  &::placeholder {
    color: ${theme.lightText};
  }

  overflow: auto;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  resize: none;

  @media (max-width: 1200px) {
    width: 300px;
    height: 400px;
  }
`;
