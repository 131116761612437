export const isValidJson = (inputJson) => {
  try {
    JSON.parse(inputJson);
    return true;
  } catch (error) {
    return false;
  }
};

export const convertJsonToFunction = (inputJson) => {
  try {
    const parsed = JSON.parse(inputJson);
    const schema = generateSchema(parsed);

    if (schema) {
      return {
        name: "your_function_name",
        description: "Your function description",
        parameters: {
          type: "object",
          properties: schema,
          required: Object.keys(schema),
        },
      };
    }

    return schema;
  } catch (error) {
    console.log("ERR", error);
    return null;
  }
};

function mergeDicts(dict1, dict2) {
  let mergedDict = { ...dict1 };
  for (let key in dict2) {
    if (mergedDict.hasOwnProperty(key)) {
      if (
        typeof mergedDict[key] === "object" &&
        typeof dict2[key] === "object"
      ) {
        mergedDict[key] = mergeDicts(mergedDict[key], dict2[key]);
      } else {
        mergedDict[key] = dict2[key];
      }
    } else {
      mergedDict[key] = dict2[key];
    }
  }
  return mergedDict;
}

function processObj(value, type) {
  if (type === "object") {
    return processDict(value);
  } else if (type === "array") {
    return processList(value);
  }
}

function processDict(value) {
  let obj = {};
  for (let k in value) {
    let fieldType = getType(value[k]);
    if (fieldType === "object") {
      let processed = processObj(value[k], fieldType);

      const required = Object.keys(processed);

      obj[k] = { type: fieldType, properties: processed, required };
    } else if (fieldType === "array") {
      let processed = processObj(value[k], fieldType);
      obj[k] = processed;
    } else {
      obj[k] = { type: fieldType };
    }
  }
  return obj;
}

function processList(listItems) {
  let obj = { type: "array" };
  let summarizedListItemObj = null;
  let itemType = null;

  for (let item of listItems) {
    let fieldType = getType(item);
    if (!itemType) {
      itemType = fieldType;
    }
    if (itemType && itemType !== fieldType) {
      console.log("INCONSISTENT TYPE ERROR, SKIP THIS ELEMENT");
      continue;
    }
    if (["object", "array"].includes(fieldType)) {
      let processed = processObj(item, fieldType);
      if (summarizedListItemObj) {
        summarizedListItemObj = mergeDicts(summarizedListItemObj, processed);
      } else {
        summarizedListItemObj = processed;
      }
    }
  }
  let finalObj = { type: itemType };
  if (summarizedListItemObj) {
    finalObj["properties"] = summarizedListItemObj;
  }
  obj["items"] = finalObj;
  return obj;
}

function getType(value) {
  if (typeof value === "string") {
    // if (isValidDate(value)) {
    //   return "date";
    // } else {
    // }
    return "string";
  } else if (typeof value === "number" && Number.isInteger(value)) {
    return "integer";
  } else if (typeof value === "number") {
    return "number";
  } else if (typeof value === "boolean") {
    return "boolean";
  } else if (Array.isArray(value)) {
    return "array";
  } else if (typeof value === "object") {
    return "object";
  }
}

export function generateSchema(jsonObject) {
  let fieldType = getType(jsonObject);
  let processed = processObj(jsonObject, fieldType);
  return processed;
}

export function flattenObject(obj, prefix = "") {
  let result = [];

  for (let key in obj) {
    if (typeof obj[key] === "object" && obj[key] !== null) {
      result = result.concat(flattenObject(obj[key], prefix + key + "__"));
    } else {
      result.push({ key: prefix + key, type: getType(obj[key]) });
    }
  }
  return result;
}
